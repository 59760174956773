import { Context } from "../context/base";

export function initializeCard(context: Context) {
  context.scene.scene2.add(context.card.card);

  if (context.card.outline) {
    context.scene.scene2.add(context.card.outline);
  }

  if (context.textures.shadowTexture) {
    context.scene.scene.add(context.card.shadow);
  }

  const setSizeOfObject = (context: Context) => {
    const containerWidth = context.html.canvas.clientWidth;
    const containerHeight = context.html.canvas.clientHeight;

    const vFov = (context.scene.camera.fov * Math.PI) / 180;
    const height = 2 * Math.tan(vFov / 2) * context.scene.camera.position.z;
    const aspect = containerWidth / containerHeight;
    const width = height * aspect;
    const pixelSize =
      containerWidth * ((1 / width) * context.state.currentWidth);

    const scale = context.state.realWidth / pixelSize;

    context.card.group.scale(scale);

    context.state.currentWidth *= scale;

    const cardRatio = context.card.aspectRatio;
    const cardHeight = context.state.realWidth / cardRatio;

    const fov = 15 / (cardHeight / containerHeight);

    context.scene.camera.fov = fov;
    context.scene.camera.updateProjectionMatrix();
  };

  context.state.resizeEvents.push(setSizeOfObject);
}
