import { load } from "./load";

export interface IBleededImage {
  bleed: number;
  width: number;
  height: number;
  src: string;
}

export async function crop({ bleed, width, height, src }: IBleededImage) {
  const img = await load(src);

  if (!img) {
    return;
  }

  const bleedlessWidth = width - bleed;
  const bleedlessHeight = height - bleed;

  const canvas = document.createElement("canvas");
  canvas.width = bleedlessWidth - 2;
  canvas.height = bleedlessHeight - 2;

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Can't get canvas context");
  }

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.drawImage(img, -(bleed / 2) - 1, -(bleed / 2) - 1);

  const dataUrl = canvas.toDataURL();

  return dataUrl;
}
