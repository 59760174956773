import { WebGLRenderer } from "three";
import { Context } from "../context/base";

export function initializeRenderer(context: Context) {
  const pixelRatio = Math.max(window.devicePixelRatio, 2);

  const renderer = new WebGLRenderer({
    canvas: context.html.canvas,
    alpha: true,
    antialias: pixelRatio <= 2,
    powerPreference: "high-performance",
  });

  renderer.autoClear = false;

  const winHeightElem = document.querySelector(".card-window-height");

  renderer.setSize(context.html.viewer.clientWidth, winHeightElem.clientHeight);

  renderer.setPixelRatio(pixelRatio);

  const updateRenderer = (context: Context) => {
    renderer.setSize(
      context.html.viewer.clientWidth,
      winHeightElem.clientHeight
    );
    renderer.setPixelRatio(pixelRatio);
  };

  context.state.resizeEvents.push(updateRenderer);

  return renderer;
}
