import { DirectionalLight, PointLight } from "three";
import { SceneContext } from "../base";

export function buildDefaultLights(sceneContext: SceneContext) {
  const light = new DirectionalLight(0xffffff);
  light.position.x = 0;
  light.position.y = 0;
  light.position.z = 4;
  light.intensity = Math.PI;

  sceneContext.scene.add(light);
  sceneContext.scene2.add(light);

  sceneContext.camera.add(new PointLight(0xffffff, 1));

  return light;
}
