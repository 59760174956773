import { debounce } from "../../utils/debounce";
import { Context } from "../context/base";

export async function initializeResizer(context: Context) {
  const resize = async () => {
    for (const resizingFunction of context.state.resizeEvents) {
      await resizingFunction(context);
    }
  };

  const fullResize = async () => {
    await resize();
    await resize();
  };

  await fullResize();

  const debouncedResize = debounce(fullResize, 0);

  window.addEventListener("resize", debouncedResize);
  window.visualViewport.addEventListener("resize", debouncedResize);
}
