import {
  Color,
  MeshBasicMaterial,
  MeshStandardMaterial,
  RepeatWrapping,
} from "three";
import { IBuildMaterials, ICardMaterials } from "../base";

export function buildPaperTextures({
  textureContext,
}: IBuildMaterials): ICardMaterials {
  const frontTexture = textureContext.front.clone();
  frontTexture.wrapS = RepeatWrapping;
  frontTexture.repeat.x = -1;

  const whiteColor = new Color(0xffffff);

  const frontMaterial = new MeshStandardMaterial({
    map: frontTexture,
    wireframe: false,
  });

  frontMaterial.emissiveIntensity = 0;

  const backMaterial = new MeshStandardMaterial({
    map: textureContext.back,

    wireframe: false,
  });
  backMaterial.emissiveIntensity = 0;

  var sideMaterial = new MeshBasicMaterial();

  frontMaterial.color = whiteColor;
  backMaterial.color = whiteColor;

  frontMaterial.emissiveIntensity = 0;

  if (!textureContext.bumpMap) {
    throw new Error("Expected bumpMap");
  }

  frontMaterial.bumpMap = textureContext.bumpMap;
  backMaterial.bumpMap = textureContext.bumpMap;

  const bumpScale = 20;
  const roughness = 0.6;

  frontMaterial.bumpScale = bumpScale;
  backMaterial.bumpScale = bumpScale;

  frontMaterial.roughness = roughness;
  backMaterial.roughness = roughness;

  return { side: sideMaterial, back: backMaterial, front: frontMaterial };
}
