import { BASE_WIDTH, SHADOW_SIZE } from "../constants";

export interface IMakeShadowProps {
  bleed: number;
  width: number;
  height: number;
  radius: number;
  color: string;
}

const MARGIN = 10;

export function makeShadow({
  bleed,
  width,
  height,
  radius,
  color,
}: IMakeShadowProps) {
  const bleedlessWidth = width - bleed;
  const bleedlessHeight = height - bleed;

  const canvas = document.createElement("canvas");

  canvas.width = bleedlessWidth + SHADOW_SIZE * 2 + MARGIN;
  canvas.height = bleedlessHeight + SHADOW_SIZE * 2 + MARGIN;

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Can't get canvas context");
  }

  const borderRadiusPx = radius * (bleedlessWidth / BASE_WIDTH);

  ctx.shadowColor = color;
  ctx.shadowBlur = SHADOW_SIZE;

  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.roundRect(
    SHADOW_SIZE + MARGIN / 2,
    SHADOW_SIZE + MARGIN / 2,
    bleedlessWidth,
    bleedlessHeight,
    [borderRadiusPx]
  );
  ctx.fill();

  const dataUrl = canvas.toDataURL();

  return dataUrl;
}
