import { debounce } from "../../utils/debounce";
import { Context } from "../context/base";

export function initializeMouseEffects(context: Context) {
  const targetRot: { x: number | undefined; y: number | undefined } = {
    x: undefined,
    y: undefined,
  };
  let initialBump = (context.card.materials.back as any).bumpScale || 0;

  let isMouseDown = false;

  let initialX = 0;
  let lastX = 0;

  const rotateTowards = (towardsX: boolean) => {
    const targetRotKey = towardsX ? "x" : "y";
    const groupKey = towardsX ? "rotateX" : "rotateY";

    if (targetRot[targetRotKey] === undefined) {
      return;
    }

    const deltaX = targetRot[targetRotKey]! - context.card.group[groupKey];

    const rotateRight = deltaX > 0;
    const sign = rotateRight ? 1 : -1;

    const speed = Math.max(Math.abs(deltaX * 0.01), 0.0005);

    context.card.group[groupKey] +=
      sign * speed * context.state.currentTimeDelta;

    let bumpDamp = (context.card.group[groupKey] % Math.PI) / Math.PI;
    bumpDamp = Math.abs(bumpDamp);

    bumpDamp = Math.min(1 - bumpDamp, bumpDamp);
    bumpDamp *= 2;

    bumpDamp = 1 - bumpDamp ** 2;

    (context.card.materials.back as any).bumpScale = initialBump * bumpDamp;
    (context.card.materials.front as any).bumpScale = initialBump * bumpDamp;

    const pastGoal = rotateRight
      ? context.card.group[groupKey] > targetRot[targetRotKey]!
      : context.card.group[groupKey] < targetRot[targetRotKey]!;

    if (pastGoal) {
      context.card.group[groupKey] = targetRot[targetRotKey]!;
      targetRot[targetRotKey] = undefined;
    }
  };

  const animate = () => {
    if (!context.state.canMoveMouse) {
      targetRot.x = 0;
      targetRot.y = 0;

      if (context.state.frontShown) {
        targetRot.y += Math.PI;
      }
      return;
    }

    if (targetRot.x === undefined && targetRot.y === undefined) {
      if (context.state.isResetting) {
        context.state.frontShown =
          Math.floor((context.card.group.rotateY + Math.PI / 2) / Math.PI) %
            2 ===
          0;

        context.card.group.rotateY = context.state.frontShown ? 0 : Math.PI;

        context.state.isResetting = false;
      }
      return;
    }

    rotateTowards(true);
    rotateTowards(false);
  };

  const handleMove = (x: number, y: number, reset: boolean = false) => {
    if (!context.state.canMoveMouse) {
      return;
    }

    if (context.state.isResetting) {
      return;
    }

    context.state.isResetting = reset;

    const centerX = window.innerWidth / 2;

    let dx = x - initialX;

    if (reset) {
      dx = 0;
    }

    const ratioX = dx / centerX;
    targetRot.y = ratioX * Math.PI * 0.95;

    targetRot.y = Math.min(targetRot.y, Math.PI);
    targetRot.y = Math.max(targetRot.y, -Math.PI);

    if (!context.state.frontShown) {
      targetRot.y += Math.PI;
    }

    if (reset) {
      const frontShown =
        Math.floor((context.card.group.rotateY + Math.PI / 2) / Math.PI) % 2 ===
        0;

      const dx_ = x - initialX;

      if (frontShown !== context.state.frontShown) {
        if (dx_ > 0) {
          targetRot.y += Math.PI;
        } else {
          targetRot.y -= Math.PI;
        }
      }

      return;
    }
  };

  const handleMouseDown = (e: MouseEvent) => {
    if (isMouseDown) {
      isMouseDown = false;
      context.state.disableClick = false;
      handleMove(e.pageX, window.innerHeight / 2, true);

      return;
    }

    if (e.button > 0) {
      return;
    }

    isMouseDown = true;
    initialX = e.clientX;
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (!isMouseDown) {
      return;
    }

    isMouseDown = false;

    if (Math.abs(e.clientX - initialX) > 10) {
      handleMove(e.pageX, window.innerHeight / 2, true);
      return;
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isMouseDown) {
      return;
    }

    if (!context.state.disableClick && Math.abs(e.clientX - initialX) > 10) {
      context.state.disableClick = true;
    }
    handleMove(e.pageX, e.pageY);
  };

  const handleTouchStart = (e: TouchEvent) => {
    initialX = e.touches[0].clientX;
    lastX = e.touches[0].clientX;
  };

  const handleTouch = (e: TouchEvent) => {
    lastX = e.touches[0].clientX;

    if (
      !context.state.disableClick &&
      Math.abs(e.touches[0].clientX - initialX) > 10
    ) {
      context.state.disableClick = true;
    }

    handleMove(e.touches[0].clientX, e.touches[0].clientY);
  };
  const handleTouchEnd = (e: TouchEvent) => {
    if (context.state.velocity > 1) {
      const frontShown =
        Math.floor((context.card.group.rotateY + Math.PI / 2) / Math.PI) % 2 ===
        0;

      if (frontShown === context.state.frontShown) {
        return;
      }
    }

    if (Math.abs(lastX - initialX) > 10) {
      handleMove(lastX, window.innerHeight / 2, true);
      return;
    }
  };

  window.addEventListener("mousedown", handleMouseDown);
  window.addEventListener("mouseup", handleMouseUp);
  context.html.viewer.addEventListener(
    "mousemove",
    debounce(handleMouseMove, 30)
  );
  context.html.viewer.addEventListener("touchstart", handleTouchStart);
  context.html.viewer.addEventListener("touchmove", handleTouch);
  context.html.viewer.addEventListener("touchend", handleTouchEnd);
  context.html.viewer.addEventListener("touchcancel", handleTouchEnd);

  return animate;
}
