import CubicBezier from '@thednp/bezier-easing';
import { StateContext } from "../context/base";

export const Transition = (
  state: StateContext,
  curve: number[],
  duration: number
) => {
  const [a, b, c, d] = curve;

  let currentTime = 0;
  let isCompleted = false;

  const easing = new CubicBezier(a, b, c, d) as any as Function;

  const getValue = () => {
    currentTime += state.currentTimeDelta;

    if (currentTime > duration) {
      isCompleted = true;
      return 1;
    }

    const ratio = currentTime / duration;

    return easing(ratio);
  };

  return {
    getValue,
    completed: () => isCompleted,
  };
};

export interface ITransitionProp {
  curve: number[];
  duration: number;
  from: number;
  to: number;
}

export const CombineTransitions = (
  state: StateContext,
  transitions: ITransitionProp[]
) => {
  let i = 0;

  const easings = transitions.map(({ curve, duration }) =>
    Transition(state, curve, duration)
  );

  const completed = () => i === transitions.length;

  const getValue = () => {
    const easing = easings[i];

    const diff = transitions[i].to - transitions[i].from;

    const value = easing.getValue();

    const out = transitions[i].from + value * diff;

    if (easing.completed()) {
      i++;
    }

    return out;
  };

  return {
    getValue,
    completed,
  };
};

export type ITransition = ReturnType<typeof Transition>;
export type ICombineTransitions = ReturnType<typeof CombineTransitions>;
