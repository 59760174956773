import { buildContext } from "./engine/context/builder";
import { initializeRenderer } from "./engine/initialization/renderer";
import { initializeScene } from "./engine/initialization/scene";
import { initializeCard } from "./engine/initialization/card";
import { initializeUi } from "./engine/initialization/ui";
import { initializeAnimations } from "./engine/initialization/animations";
import { initializeResizer } from "./engine/initialization/resizer";
import { initializeMouseEffects } from "./engine/initialization/mouseEffects";
import { initializeSizing } from "./engine/initialization/sizing";

(async () => {
  const context = await buildContext();

  if (!context) {
    return;
  }

  const renderer = initializeRenderer(context);
  await initializeScene(context);
  initializeSizing(context);
  const { toggleUI } = initializeUi(context);
  initializeCard(context);
  const { pipeline, flip } = initializeAnimations(context);
  await initializeResizer(context);
  const animateMouse = initializeMouseEffects(context);

  context.html.canvas.style.opacity = "1";

  const globalApi = { toggleUI, flip };

  (window as any).CardViewer = globalApi;

  let lastTimestamp: number = 0;
  let delta = 0;
  let frames = 0;

  const animate = (timestamp: number) => {
    const deltaT = timestamp - (lastTimestamp || timestamp);

    lastTimestamp = timestamp;

    context.state.currentTimeDelta = deltaT;

    delta += deltaT;
    frames += 1;

    if (delta > 1000) {
      if (context.data.debug) {
        console.log(frames);
      }

      delta = delta % 1000;
      frames = 0;
    }

    pipeline.animate();
    animateMouse();

    renderer.render(context.scene.scene, context.scene.camera);
    renderer.render(context.scene.scene2, context.scene.camera);

    requestAnimationFrame(animate);
  };

  requestAnimationFrame(animate);
})();
