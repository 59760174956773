import {
  Color,
  MeshBasicMaterial,
  MeshStandardMaterial,
  RepeatWrapping,
  Vector2,
} from "three";
import { IBuildMaterials, ICardMaterials } from "../base";

export function buildVelvetTextures({
  textureContext,
}: IBuildMaterials): ICardMaterials {
  const frontTexture = textureContext.front.clone();
  frontTexture.wrapS = RepeatWrapping;
  frontTexture.repeat.x = -1;

  const whiteColor = new Color(0xffffff);

  const frontMaterial = new MeshStandardMaterial({
    map: frontTexture,
    wireframe: false,
  });

  frontMaterial.emissiveIntensity = 0;

  const backMaterial = new MeshStandardMaterial({
    map: textureContext.back,

    wireframe: false,
  });
  backMaterial.emissiveIntensity = 0;

  var sideMaterial = new MeshBasicMaterial();

  frontMaterial.color = whiteColor;
  backMaterial.color = whiteColor;

  frontMaterial.emissiveIntensity = 0;

  if (!textureContext.bumpMap) {
    throw new Error("Expected bumpMap");
  }

  const bumpMap = textureContext.bumpMap.clone();
  bumpMap.repeat = new Vector2(0.8, 0.8);
  bumpMap.wrapS = RepeatWrapping;
  bumpMap.wrapT = RepeatWrapping;

  frontMaterial.bumpMap = bumpMap;
  backMaterial.bumpMap = bumpMap;

  const bumpScale = 10;

  frontMaterial.bumpScale = bumpScale;
  backMaterial.bumpScale = bumpScale;

  return { side: sideMaterial, back: backMaterial, front: frontMaterial };
}
