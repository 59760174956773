import { Context } from "../context/base";

export async function initializeScene(context: Context) {
  const winHeightElem = document.querySelector(".card-window-height");

  const updateCamera = (context: Context) => {
    context.scene.camera.aspect =
      context.html.viewer.clientWidth / winHeightElem.clientHeight;

    context.scene.camera.updateProjectionMatrix();
  };

  context.state.resizeEvents.push(updateCamera);
}
