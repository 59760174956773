export function load(src: string): Promise<HTMLImageElement | undefined> {
  const img = new Image();

  return new Promise((resolve) => {
    img.onload = () => {
      resolve(img);
    };

    img.onerror = () => {
      resolve(undefined);
    };

    img.crossOrigin = "anonymous";
    img.setAttribute("crossorigin", "anonymous");
    img.src = src;
  });
}
