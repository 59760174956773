import {
  PerspectiveCamera,
  Scene,
} from "three";
import {
  HtmlContext,
  MaterialContext,
  SceneContext,
} from "./base";

export function setupScene(
  htmlContext: HtmlContext,
  materialContext: MaterialContext
): SceneContext {
  const scene = new Scene();
  const scene2 = new Scene();

  const camera = new PerspectiveCamera(
    75,
    htmlContext.viewer.clientWidth / htmlContext.viewer.clientHeight,
    0.1,
    1000
  );

  camera.position.z = 5;

  const light = materialContext.lightBuilder({ scene, scene2, camera });

  return { scene, scene2, camera, light };
}
