import { DataContext, HtmlContext, Materials } from "./base";

import backgroundSrc from "../../textures/background.jpg";
import sampleSrc from "../../textures/sample1.jpg";
import sample2Src from "../../textures/sample2.jpg";
import velvetSrc from "../../textures/velvet.jpg";
import paperSrc from "../../textures/paper.jpg";
import leatherSrc from "../../textures/leather.jpg";
import steelSrc from "../../textures/steel.jpg";
import { BASE_WIDTH } from "../../constants";

export function getDataContext(htmlContext: HtmlContext): DataContext {
  const height = +(htmlContext.viewer.dataset.height || 675);
  const width = +(htmlContext.viewer.dataset.width || 1125);
  const bleed = +(htmlContext.viewer.dataset.bleed || 75);
  const frontUrl = htmlContext.viewer.dataset.frontUrl || sampleSrc;
  const backUrl = htmlContext.viewer.dataset.backUrl || sample2Src;
  const backgroundUrl =
    htmlContext.viewer.dataset.backgroundUrl || backgroundSrc;

  const velvetBumpUrl = htmlContext.viewer.dataset.velvetBumpUrl || velvetSrc;
  const paperBumpUrl = htmlContext.viewer.dataset.paperBumpUrl || paperSrc;
  const steelBumpUrl = htmlContext.viewer.dataset.steelBumpUrl || steelSrc;
  const leatherBumpUrl =
    htmlContext.viewer.dataset.leatherBumpUrl || leatherSrc;
  const noneBumpUrl = htmlContext.viewer.dataset.noneBumpUrl || paperSrc;

  // falsey values return 0.001 as well as "0"

  const bleedlessWidth = width - bleed;

  const borderRadius =
    +(htmlContext.viewer.dataset.borderRadius || 0.1) /
      (bleedlessWidth / BASE_WIDTH) || 0.001;
  const shadowColor = htmlContext.viewer.dataset.shadowColor || undefined;
  const borderColor = htmlContext.viewer.dataset.borderColor || undefined;

  const material = (htmlContext.viewer.dataset.material || "none") as Materials;
  const debug = htmlContext.viewer.dataset.mode === "debug";

  return {
    height,
    width,
    bleed,
    frontUrl,
    backUrl,
    backgroundUrl,
    material,
    borderRadius,
    shadowColor,
    borderColor,
    bumpUrls: {
      velvet: velvetBumpUrl,
      paper: paperBumpUrl,
      steel: steelBumpUrl,
      leather: leatherBumpUrl,
      none: noneBumpUrl,
    },
    debug,
  };
}
