import { PADDING_Y } from "../../constants";
import { wait } from "../../utils/timing";
import { Context } from "../context/base";

export function initializeUi(context: Context) {
  const { under, ui, canvas } = context.html;

  const toggleUI = () => {
    if (!context.state.isInfoOpen) {
      ui.classList.add("--visible");
      canvas.style.visibility = "hidden";
    } else {
      ui.classList.remove("--visible");
      canvas.style.visibility = "visible";
    }

    context.state.isInfoOpen = !context.state.isInfoOpen;
  };

  if (context.data.debug) {
    under.addEventListener("click", toggleUI);
  }

  const winHeightElem = document.querySelector(".card-window-height");

  const setUIPosition = async (context: Context) => {
    const maxPixelSizeWidth = context.state.realWidth;
    const maxPixelSizeHeight = maxPixelSizeWidth / context.card.aspectRatio;

    context.html.ui.style.maxWidth = `${maxPixelSizeWidth}px`;

    await wait(0);

    const uiHeight = context.html.ui.clientHeight;

    const totalHeight = uiHeight + maxPixelSizeHeight;

    const margin = (winHeightElem.clientHeight - totalHeight) / 2;

    const cardTop = (winHeightElem.clientHeight - maxPixelSizeHeight) / 2;

    let bottom;

    if (margin < PADDING_Y) {
      context.html.canvas.style.transform = `translateY(${
        -cardTop + PADDING_Y
      }px)`;

      bottom = margin * 2 - PADDING_Y;
    } else {
      const dY = margin - cardTop;
      context.html.canvas.style.transform = `translateY(${dY}px)`;

      bottom = margin;
    }

    if (bottom < 0) {
      context.html.ui.style.bottom = `0px`;
      context.html.overlay.style.minHeight = `calc(100vh + ${bottom * -1}px)`;
    } else {
      context.html.ui.style.bottom = `${bottom}px`;
      context.html.overlay.style.minHeight = `100vh`;
    }

    context.html.ui.style.visibility = "visible";
  };

  context.state.resizeEvents.push(setUIPosition);

  return { toggleUI };
}
