import { getDataContext } from "./data";
import { Context } from "./base";
import { getHtmlContext } from "./html";
import { getTexturesContext } from "./textures";
import { setupScene } from "./scene";
import { getCardContext } from "./card";
import { getStateContext } from "./state";
import { getMaterialContext } from "./material";

export async function buildContext(): Promise<Context | undefined> {
  const htmlContext = getHtmlContext();

  if (!htmlContext) {
    return;
  }

  const dataContext = getDataContext(htmlContext);
  const materialContext = getMaterialContext(dataContext);

  const texturesContext = await getTexturesContext(dataContext);
  const cardContext = getCardContext(
    texturesContext,
    dataContext,
    materialContext
  );
  const sceneContext = setupScene(htmlContext, materialContext);
  const stateContext = getStateContext();

  return {
    html: htmlContext,
    data: dataContext,
    textures: texturesContext,
    scene: sceneContext,
    card: cardContext,
    state: stateContext,
    material: materialContext,
  };
}
