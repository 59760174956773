import { AmbientLight, PointLight } from "three";
import { SceneContext } from "../base";

export function buildPaperLights(sceneContext: SceneContext) {
  const spotlight = new PointLight(0xffffff);
  spotlight.position.x = 0;
  spotlight.position.y = 0;
  spotlight.position.z = 3;
  spotlight.intensity = 30;

  const light = spotlight;

  const light2 = new AmbientLight(0xffffff);
  light2.position.x = 0;
  light2.position.y = 0;
  light2.position.z = 4;
  light2.intensity = 2;

  sceneContext.scene.add(light2);
  sceneContext.scene2.add(light2);

  sceneContext.scene.add(light);
  sceneContext.scene2.add(light);

  return light;
}
