import { DataContext, MaterialContext, Materials } from "./base";
import { buildDefaultLights } from "./lights/default";
import { buildLeatherLights } from "./lights/leather";
import { buildPaperLights } from "./lights/paper";
import { buildSteelLights } from "./lights/steel";
import { buildVelvetLights } from "./lights/velvet";
import { buildLeatherTextures } from "./textures/leather";
import { buildMetalTextures } from "./textures/metal";
import { buildNoneTextures } from "./textures/none";
import { buildPaperTextures } from "./textures/paper";
import { buildVelvetTextures } from "./textures/velvet";

const MATERIALS: { [key in Materials]: MaterialContext } = {
  velvet: {
    lightBuilder: buildVelvetLights,
    textureBuilder: buildVelvetTextures,
  },
  steel: {
    lightBuilder: buildSteelLights,
    textureBuilder: buildMetalTextures,
  },
  paper: {
    lightBuilder: buildPaperLights,
    textureBuilder: buildPaperTextures,
  },
  leather: {
    lightBuilder: buildLeatherLights,
    textureBuilder: buildLeatherTextures,
  },
  none: {
    lightBuilder: buildDefaultLights,
    textureBuilder: buildNoneTextures,
  },
};

export function getMaterialContext(dataContext: DataContext): MaterialContext {
  return MATERIALS[dataContext.material];
}
