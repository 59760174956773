import { HtmlContext } from "./base";

const guardHtmlElements = (element: any) => {
  if (!(element instanceof HTMLElement)) {
    throw new Error("Expected element to be an HtmlElement");
  }

  return element;
};

export function getHtmlContext(): HtmlContext | undefined {
  const classes = [
    "card-viewer",
    "card-ui",
    "card-ui-under",
    "card-ui-over",
    "card-canvas",
    "card-overlay",
  ];

  const elements = classes
    .map((klass) => document.getElementsByClassName(klass)[0])
    .filter((x) => x);

  if (elements.length < classes.length) {
    return;
  }

  const [viewer, ui, under, over, canvas, overlay] =
    elements.map(guardHtmlElements);

  return { viewer, ui, under, over, canvas, overlay };
}
