import { crop } from "../../images/cropper";
import { makeShadow } from "../../images/shadow";
import { loadTexture } from "../../utils/textures";
import { DataContext, TexturesContext } from "./base";

import { LinearFilter, SRGBColorSpace, Texture } from "three";

export async function getTexturesContext(
  dataContext: DataContext
): Promise<TexturesContext> {
  const {
    frontUrl,
    backUrl,
    height,
    width,
    bleed,
    bumpUrls,
    material,
    borderRadius,
    shadowColor,
  } = dataContext;

  const front = {
    src: frontUrl,
    height,
    width,
    bleed,
  };

  const back = {
    src: backUrl,
    height,
    width,
    bleed,
  };

  const frontTexture = await loadTexture(await crop(front));
  const backTexture = await loadTexture(await crop(back));

  const shadowTexture =
    shadowColor &&
    (await loadTexture(
      makeShadow({
        width,
        height,
        bleed,
        radius: borderRadius,
        color: shadowColor,
      })
    ));

  frontTexture.generateMipmaps = false;
  frontTexture.minFilter = LinearFilter;
  frontTexture.colorSpace = SRGBColorSpace;

  frontTexture.needsUpdate = true;

  backTexture.generateMipmaps = false;
  backTexture.minFilter = LinearFilter;
  backTexture.colorSpace = SRGBColorSpace;

  backTexture.needsUpdate = true;

  if (shadowTexture) {
    shadowTexture.generateMipmaps = false;
    shadowTexture.minFilter = LinearFilter;
    shadowTexture.colorSpace = SRGBColorSpace;

    shadowTexture.needsUpdate = true;
  }

  let bumpMapTexture: Texture | undefined = undefined;

  if (material && material in bumpUrls) {
    bumpMapTexture = await loadTexture(bumpUrls[material]);
    bumpMapTexture.generateMipmaps = false;
    bumpMapTexture.minFilter = LinearFilter;
    bumpMapTexture.colorSpace = SRGBColorSpace;
    bumpMapTexture.needsUpdate = true;
  }

  return {
    front: frontTexture,
    back: backTexture,
    bumpMap: bumpMapTexture,
    shadowTexture: shadowTexture,
  };
}
