import { BufferGeometry, Object3D } from "three";

export class Group {
  objects: Object3D[];

  private rotateX_: number;
  private rotateY_: number;
  private scale_: number;
  private overscale_: number;

  private resultingScale_: number;
  private geometries_: BufferGeometry[];

  constructor(objects: Object3D[], geometries: BufferGeometry[]) {
    this.objects = objects;
    this.rotateX_ = 0;
    this.rotateY_ = 0;
    this.scale_ = 1;
    this.overscale_ = 1;
    this.resultingScale_ = 1;
    this.geometries_ = geometries;
  }

  get rotateX() {
    return this.rotateX_;
  }
  set rotateX(newRotateX) {
    this.rotateX_ = newRotateX;

    for (const obj of this.objects) {
      obj.rotation.x = this.rotateX_;
    }
  }

  get rotateY() {
    return this.rotateY_;
  }
  set rotateY(newRotateY) {
    this.rotateY_ = newRotateY;

    for (const obj of this.objects) {
      obj.rotation.y = this.rotateY_;
    }
  }

  applyScale() {
    const nextResultingScale = this.scale_ * this.overscale_;

    const ratio = nextResultingScale / this.resultingScale_;

    for (const geometry of this.geometries_) {
      geometry.scale(ratio, ratio, ratio);
    }

    this.resultingScale_ = nextResultingScale;
  }

  scale(by: number) {
    this.scale_ *= by;

    this.applyScale();
  }

  overscale(by: number) {
    this.overscale_ *= by;

    this.applyScale();
  }

  setOverscale(val: number) {
    this.overscale_ = val;

    this.applyScale();
  }
}
