import { StateContext } from "../context/base";
import { Animation } from "./animation";
import { Group } from "../../structures/group";
import {
  CombineTransitions,
  ICombineTransitions,
  ITransition,
  Transition,
} from "./transition";

enum AnimationState {
  STARTING,
  SCALING_UP,
  ROTATING,
  SCALING_DOWN,
}

const EASE_IN_OUT = [0.42, 0, 0.58, 1];
const EASE_OUT = [0.45, 0.81, 0.76, 1];
const EASE_IN = [0.38, 0, 0.69, 0.51];

export class ClickRotation extends Animation {
  group: Group;
  state: StateContext;
  targetRotation = 0;

  animationState: AnimationState;
  currentScale: number;
  scaleDownTransition: ICombineTransitions;
  rotationTransition: ITransition;
  scaleUpTransition: ITransition;

  constructor(group: Group, state: StateContext) {
    super();
    this.group = group;
    this.state = state;

    this.animationState = AnimationState.STARTING;
    this.currentScale = 1;

    this.scaleUpTransition = Transition(state, EASE_IN_OUT, 400);

    this.scaleDownTransition = CombineTransitions(state, [
      { curve: EASE_IN, duration: 200, from: 1.1, to: 1 },
      { curve: EASE_OUT, duration: 100, from: 1, to: 1.025 },
      { curve: EASE_IN, duration: 100, from: 1.025, to: 1 },
      { curve: EASE_OUT, duration: 50, from: 1, to: 1.0125 },
      { curve: EASE_IN, duration: 50, from: 1.0125, to: 1 },
    ]);
    this.rotationTransition = Transition(state, EASE_IN_OUT, 400);

    this.state.canMoveMouse = false;
  }

  starting() {
    this.targetRotation =
      (Math.round(this.group.rotateY / Math.PI) + 1) * Math.PI;

    this.animationState = AnimationState.SCALING_UP;
  }

  scalingUp() {
    this.group.setOverscale(this.scaleUpTransition.getValue() * 0.1 + 1);

    if (this.scaleUpTransition.completed()) {
      this.animationState = AnimationState.ROTATING;
    }
  }

  scalingDown() {
    this.group.setOverscale(this.scaleDownTransition.getValue());

    if (this.scaleDownTransition.completed()) {
      this.group.rotateY = this.state.frontShown ? Math.PI : 0;
      this.state.frontShown = !this.state.frontShown;
      this.state.canMoveMouse = true;
      this.complete();
    }
  }

  rotating() {
    let rotation = this.rotationTransition.getValue() * Math.PI;

    if (!this.state.frontShown) {
      rotation += Math.PI;
    }

    this.group.rotateY = rotation;

    if (this.rotationTransition.completed()) {
      this.animationState = AnimationState.SCALING_DOWN;
    }
  }

  animate(): void {
    switch (this.animationState) {
      case AnimationState.STARTING:
        this.starting();
        break;
      case AnimationState.SCALING_UP:
        this.scalingUp();
        break;
      case AnimationState.ROTATING:
        this.rotating();
        break;
      case AnimationState.SCALING_DOWN:
        this.scalingDown();
        break;
    }
  }
}
