import { SpotLight } from "three";
import { SceneContext } from "../base";

export function buildLeatherLights(sceneContext: SceneContext) {
  const spotlight = new SpotLight(0xffffff);
  spotlight.position.x = 0;
  spotlight.position.y = 0;
  spotlight.position.z = 3;
  spotlight.intensity = 30;

  const light = spotlight;

  sceneContext.scene.add(light);
  sceneContext.scene2.add(light);

  return light;
}
