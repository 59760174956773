import { TextureLoader, Texture } from "three";

const DEFAULT_DATA_URI =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAIAAAD91JpzAAABg2lDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TRSkVh3YQEcxQnezi91irUIQKoVZo1cHk0i9o0pCkuDgKrgUHPxarDi7Oujq4CoLgB4iri5Oii5T4v7TQIsaD4368u/e4ewcI9TLTrK4YoOm2mUrExUx2Vex5RQDDCGEKMzKzjDlJSsJzfN3Dx9e7KM/yPvfn6FNzFgN8InGMGaZNvEE8vWkbnPeJw6woq8TnxGMmXZD4ketKk984F1wWeGbYTKfmicPEYqGDlQ5mRVMjniSOqJpO+UKmySrnLc5aucpa9+QvDOb0lWWu0xxCAotYggQRCqoooQwbUVp1UiykaD/u4R90/RK5FHKVwMixgAo0yK4f/A9+d2vlJ8abScE40P3iOB8jQM8u0Kg5zvex4zROAP8zcKW3/ZU6MPtJeq2tRY6A/m3g4rqtKXvA5Q4w8GTIpuxKfppCPg+8n9E3ZYHQLRBYa/bW2sfpA5CmrpI3wMEhMFqg7HWPd/d29vbvmVZ/Pz77cvi/o+7EAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH5wofEzYa70Gr1AAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAALSURBVAjXY2BABgAADgABMendFQAAAABJRU5ErkJggg==";

export function loadTexture(src: string | undefined): Promise<Texture> {
  let src_ = src;

  if (!src_) {
    src_ = DEFAULT_DATA_URI;
  }

  var textureLoader = new TextureLoader();

  return new Promise((resolve, reject) => {
    textureLoader.load(src_!, resolve, undefined, () => {
      textureLoader.load(DEFAULT_DATA_URI, resolve, undefined, undefined);
    });
  });
}
