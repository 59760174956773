import {
  BackSide,
  BufferGeometry,
  Color,
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
} from "three";
import {
  CardContext,
  DataContext,
  MaterialContext,
  TexturesContext,
} from "./base";
import { BASE_THICKNESS, BASE_WIDTH, SHADOW_SIZE } from "../../constants";
import { ContourLine, RoundedSolid } from "../../geometry/roundedBox";
import { Group } from "../../structures/group";

const OUTLINE_SIZE = 0.002;

export function getCardContext(
  textureContext: TexturesContext,
  dataContext: DataContext,
  materialContext: MaterialContext
): CardContext {
  const { front, side, back } = materialContext.textureBuilder({
    textureContext,
    dataContext,
  });

  const bleedlessWidth = dataContext.width - dataContext.bleed;
  const bleedlessHeight = dataContext.height - dataContext.bleed;

  const shadowRatioW = (SHADOW_SIZE * 2 + bleedlessWidth) / bleedlessWidth;
  const shadowRatioH = (SHADOW_SIZE * 2 + bleedlessHeight) / bleedlessHeight;

  const aspectRatio = bleedlessWidth / bleedlessHeight;
  const baseHeight = BASE_WIDTH / aspectRatio;

  const cardGeometry = RoundedSolid(
    BASE_WIDTH,
    baseHeight,
    dataContext.borderRadius,
    BASE_THICKNESS
  );

  const card = new Mesh(cardGeometry, [front, side, back]);

  const shadowGeometry = new PlaneGeometry(
    shadowRatioW * BASE_WIDTH,
    baseHeight * shadowRatioH
  );
  const shadowMaterial = new MeshBasicMaterial({
    map: textureContext.shadowTexture,
    transparent: true,
    side: DoubleSide,
  });
  const shadow = new Mesh(shadowGeometry, shadowMaterial);

  const groupObjects: Object3D[] = [card, shadow];
  const geometries: BufferGeometry[] = [cardGeometry, shadowGeometry];

  let outline: Object3D | undefined = undefined;

  if (dataContext.borderColor) {
    const borderColor = new Color(dataContext.borderColor);

    var outlineMaterial = new MeshBasicMaterial({
      color: borderColor,
      side: BackSide,
    });

    const outlineGeometry = RoundedSolid(
      BASE_WIDTH,
      BASE_WIDTH / aspectRatio,
      dataContext.borderRadius,
      BASE_THICKNESS
    );

    outline = new Mesh(outlineGeometry, outlineMaterial);

    outline.scale.x = 1 + OUTLINE_SIZE;
    outline.scale.y = 1 + OUTLINE_SIZE * aspectRatio;

    groupObjects.push(outline);
    geometries.push(outlineGeometry);
  }

  return {
    materials: { front, back, side, shadow: shadowMaterial },
    aspectRatio,
    geometries,
    card,
    shadow,
    outline,
    group: new Group(groupObjects, geometries),
  };
}
