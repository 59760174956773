import { wait } from "./timing";

export function debounce(fn: (...args: any[]) => unknown, ms: number) {
  let canRun = true;
  let midCalled = undefined;

  return async (...args) => {
    if (!canRun) {
      midCalled = args;
      return;
    }

    canRun = false;

    await fn(...args);

    await wait(ms);

    if (midCalled) {
      await fn(...midCalled);
      midCalled = undefined;
    }

    canRun = true;
  };
}
