import { BufferGeometry, ExtrudeGeometry, Shape } from "three";

function ContourShape(width: number, height: number, radius: number) {
  let x = -(width / 2);
  let y = -(height / 2);

  let shape = new Shape();
  shape.moveTo(x, y + radius);
  shape.lineTo(x, y + height - radius);
  shape.quadraticCurveTo(x, y + height, x + radius, y + height);
  shape.lineTo(x + width - radius, y + height);
  shape.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
  shape.lineTo(x + width, y + radius);
  shape.quadraticCurveTo(x + width, y, x + width - radius, y);
  shape.lineTo(x + radius, y);
  shape.quadraticCurveTo(x, y, x, y + radius);

  return shape;
}

export function ContourLine(width: number, height: number, radius: number) {
  const shape = ContourShape(width, height, radius);

  const geometry = new BufferGeometry().setFromPoints(shape.getPoints(1));

  geometry.center();

  return geometry;
}

export function RoundedSolid(
  width: number,
  height: number,
  radius: number,
  depth: number
) {
  const shape = ContourShape(width, height, radius);

  const geometry = new ExtrudeGeometry(shape, {
    //steps: 2,
    depth,
    bevelEnabled: false,
  });

  const uvs = geometry.getAttribute("uv");

  const minX = -(width / 2);
  const minY = -(height / 2);

  for (let i = 0; i < uvs.count; i++) {
    const x = uvs.getX(i);
    const y = uvs.getY(i);

    uvs.setX(i, (x - minX) / width);
    uvs.setY(i, (y - minY) / height);
  }

  geometry.clearGroups();

  const group_count = [0, 0, 0];
  const group_start: (number | null)[] = [null, null, null];
  for (let i = 1; i <= geometry.attributes.normal.count; i++) {
    const index = 2 + (3 * i - 3);
    const vIndex = i - 1;
    const z = geometry.attributes.normal.array[index];

    const groupIdx = { [1]: 0, [0]: 1, [-1]: 2 }[z] || 0;
    group_count[groupIdx]++;
    group_start[groupIdx] =
      group_start[groupIdx] == null ? vIndex : group_start[groupIdx];
  }

  geometry.addGroup(group_start[0] as number, group_count[0], 2);
  geometry.addGroup(group_start[1] as number, group_count[1], 1);
  geometry.addGroup(group_start[2] as number, group_count[2], 0);

  geometry.center();

  return geometry;
}
