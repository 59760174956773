import { Pipeline } from "../animations/pipeline";
import { ClickRotation } from "../animations/click";
import { Context } from "../context/base";
import { VerticalRotation } from "../animations/vertical";

export function initializeAnimations(context: Context) {
  const pipeline = new Pipeline();

  const verticalAnimation = new ClickRotation(
    context.card.group,
    context.state
  );
  pipeline.add(verticalAnimation);

  const addAnimation = (forwards: boolean, click: boolean) => {
    if (context.state.disableClick) {
      return;
    }

    if (context.state.isResetting) {
      return;
    }

    if (pipeline.isRunning) {
      return;
    }

    if (click) {
      pipeline.add(new ClickRotation(context.card.group, context.state));

      return;
    }

    pipeline.add(
      new VerticalRotation(context.card.group, context.state, forwards)
    );
  };

  const handleClick = () => {
    if (context.state.disableClick) {
      context.state.disableClick = false;

      return;
    }

    addAnimation(true, true);
  };

  let initialX = 0;
  let lastX = 0;
  let startTime = 0;

  const handleTouchStart = (e: TouchEvent) => {
    initialX = e.touches[0].clientX;
    lastX = initialX;
    startTime = new Date().getTime();
    context.state.velocity = 0;
  };

  const handleTouchMove = (e: TouchEvent) => {
    lastX = e.touches[0].clientX;

    const deltaX = lastX - initialX;
    const distance = Math.abs(deltaX);
    const deltaT = new Date().getTime() - startTime;

    context.state.velocity = distance / deltaT;
  };

  const handleTouchEnd = (e: TouchEvent) => {
    const deltaX = lastX - initialX;

    if (context.state.velocity > 1) {
      context.state.disableClick = false;

      const frontShown =
        Math.floor((context.card.group.rotateY + Math.PI / 2) / Math.PI) % 2 ===
        0;

      if (frontShown === context.state.frontShown) {
        addAnimation(deltaX > 0, false);
      }
    }
  };

  context.html.viewer.addEventListener("click", handleClick);
  context.html.viewer.addEventListener("touchstart", handleTouchStart);
  context.html.viewer.addEventListener("touchmove", handleTouchMove);
  context.html.viewer.addEventListener("touchend", handleTouchEnd);

  return { pipeline, flip: handleClick };
}
