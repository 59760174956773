import { MAX_WIDTH, PADDING } from "../../constants";
import { Context } from "../context/base";

export function initializeSizing(context: Context) {
  const setupSizing = (context: Context) => {
    const containerWidth = context.html.viewer.clientWidth;
    const availableWidth = containerWidth - 2 * PADDING;
    context.state.realWidth =
      availableWidth < MAX_WIDTH ? availableWidth : MAX_WIDTH;
  };

  setupSizing(context);

  context.state.resizeEvents.push(setupSizing);
}
