import { StateContext } from "../context/base";
import { Animation } from "./animation";
import { Group } from "../../structures/group";

const ROTATION_SPEED = 0.006;

export class VerticalRotation extends Animation {
  group: Group;
  state: StateContext;
  isStarted = false;
  targetRotation = 0;
  forwards: boolean;

  constructor(group: Group, state: StateContext, forwards: boolean) {
    super();
    this.group = group;
    this.state = state;
    this.forwards = forwards;

    this.state.canMoveMouse = false;
  }

  animate(): void {
    if (!this.isStarted) {
      const rotationAdd = this.forwards ? 1 : -1;

      this.targetRotation =
        (Math.round(this.group.rotateY / Math.PI) + rotationAdd) * Math.PI;

      this.isStarted = true;
    }

    const rotation = ROTATION_SPEED * this.state.currentTimeDelta;

    if (this.forwards) {
      this.group.rotateY += rotation;
    } else {
      this.group.rotateY -= rotation;
    }

    const isCompleted = this.forwards
      ? this.group.rotateY > this.targetRotation
      : this.group.rotateY < this.targetRotation;

    if (isCompleted) {
      this.group.rotateY = this.state.frontShown ? Math.PI : 0;
      this.state.frontShown = !this.state.frontShown;
      this.state.canMoveMouse = true;
      this.complete();
    }
  }
}
