import { BASE_WIDTH } from "../../constants";
import { StateContext } from "./base";

export function getStateContext(): StateContext {
  return {
    currentWidth: BASE_WIDTH,
    realWidth: 0,
    isInfoOpen: false,
    resizeEvents: [],
    currentTimeDelta: 0,
    frontShown: true,
    canMoveMouse: false,
    disableClick: false,
    isResetting: false,
    velocity: 0,
  };
}
