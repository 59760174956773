import { Animation } from "./animation";

export class Pipeline {
  animations: Animation[] = [];

  currentAnimation?: Animation;

  isRunning = false;

  add(animation: Animation) {
    this.animations.push(animation);
  }

  animate() {
    if (this.currentAnimation) {
      this.isRunning = true;
      this.currentAnimation.animate();

      if (this.currentAnimation.isCompleted) {
        this.currentAnimation = undefined;

        this.isRunning = false;
      }

      return;
    }

    this.currentAnimation = this.animations.shift();
  }
}
